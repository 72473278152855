.about-container{
height: 100vh;  max-height: -webkit-fill-available;
position: relative;
}

.about-me{
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  flex-direction: column;
  padding: 20px;
}

.expand-button{
  background-color: #0b0b0b;
  border: 1px solid white;
  width: 85px;
  height: 25px;
  grid-column: 13/17;
  color: #f9f9f9;
}


.about-me p{
  padding: 10px;
  margin-bottom: 30px;
  color: #f9f9f9;
}

.about-me-passage p{
  
  padding: 10px;
  margin-bottom: 30px;
  color: #f9f9f9;
}

.about-me-name{
  display: flex;
  max-width: 500px;
  padding: 10px;
  font-size: 35px;
  flex-wrap: wrap;
  overflow: hidden;
}

.img-settings1 {
  display: block;
  max-width: 95%;
  max-height:500px;
}

.about-me-passage{
  width: 100vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2vw repeat(18,1fr) 2vw;
  column-gap: 1.6rem;
  position: relative;
  margin-bottom: 125px;
}

.section1{
  grid-column: 9/19;
  font-size: 35px;
  font-weight: 300;
  text-align: center;
}

.section7{
  grid-column: 9/19;
  font-size: 40px;
  font-weight: 300;
  text-align: center;
}

.section2{
  grid-column: 9/14;
  font-weight: 300;
}

.opacityblur {
  opacity: 0.6;
  filter: blur(3px);
}

.section3{
  grid-column: 14/19;
  font-weight: 300;
}

.section4{
  grid-column: 1/19;
  font-size: 100px;
  text-align: center;
}

.section5{
  grid-column: 3/7;
}

.section6{

  grid-column: 9/19;
  font-weight: 300;
}

.section8{
  display: none;
}

.section9{
  grid-column: 3/7;
  text-align: center;
}

.vid-section{
  grid-column: 2/8;
  width: 35vw;
}

.pop-font{
  font-size: 20px;
  max-width: 75%;
  margin: auto;
  position: absolute;
  left: 0; right: 0;
  top: 20%;
  color: #f9f9f9;
}

.pop-up-heading{
  font-size: 50px;
  position: absolute;
  margin-bottom: 7%;
  left: 8%;
  top: 4%;
  color: #f9f9f9;
}

@media screen and (max-width: 767px){

  .pop-font{
    font-size: 15px;
  }

  .pop-up-heading{
    font-size: 30px;
  }
  
  .about-me-passage{
    display: block;
    width: 88vw;
  }

  .section4{
    font-size: 60px;
  }

  .section5{
    display: none;
  }

  .section2{
    text-align: center;
  }

  .section3{
    text-align: center;
  }

  .vid-section{
    width: 90vw;
  }

  .section1{
    text-align: center;
    font-size: 30px;
  }

  .section8{
    grid-column: 3/7;
    display: block;
    text-align: center;
  }

  .section7{
    font-size: 20px;
  }

  .expand-button{
    margin:0 auto;
    display:block;
  }

}