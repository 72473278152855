.error404{
    color: #f9f9f9;
    font-weight: 300;
    font-size: 90px;
    height: 75vh;
    display: flex;
    position: relative;
    align-items: center;
    text-align: center;

    
}

.errorFlex{ 
    display: flex;
    justify-content: space-around;
  
}

  @media screen and (max-width: 542px) {
    .error404{
        font-size: 50px;
    }
  }
  
  @media screen and (max-width: 350px) {
    .error404{
        font-size: 40px;
    }
  }