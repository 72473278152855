.container{
background-color: #0b0b0b;
height: auto;
position: relative;

}

.heading{    
  position: relative;
  height: 15%;
  font-size: 90px;
  left: 5%;
  font-weight: 300;
  width: 80%;
  color: #f9f9f9;
}

.heading1{    
  position: relative;
  height: 15%;
  font-size: 90px;
  left: 5%;
  font-weight: 300;
  width: 80%;
  color: #f9f9f9;
}



 .compPara{
  position: relative;
  left: 5%;
  font-weight: 300;  
  width: 80%;
  top: 30px;
  height: 0px;
 
}
.heading:hover ~ .cursor{
  transform: scale(5);
}

.polaroids{
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 160px;

}


.polaroid-1{
  position: relative;
  justify-self: center;
  grid-column-start: 1;
  grid-column-end: 3;
  max-width: 80%;
    
}

.polaroid-2{

  position: relative;
  justify-self: center;
  grid-column-start: 3;
  grid-column-end: 5;
  max-width: 80%;
}

.polaroid-3{

  position: relative;
  justify-self: center;
  grid-column-start: 5;
  grid-column-end: 7;
  max-width: 80%;

}

@media screen and (max-width: 1421px) and (min-width: 1082px) {

  .polaroid-1{
    grid-column-start: 1;
    grid-column-end: 4;
  }
  
  .polaroid-2{
      grid-column-start: 4;
    grid-column-end: 7;
  }
  
  .polaroid-3{
    grid-column-start: 2;
    grid-column-end: 5;
    left: 17.5%;
  }

}

@media screen and (max-width: 500px) and (min-width: 350px) {
  .polaroids{
    margin-top: 20px;
  }
}

@media screen and (max-width: 350px) {
  .polaroids{
    margin-top: 0px;
  }
}

@media screen and (max-width: 1082px) {

  .polaroid-1{
    grid-column-start: 1;
    grid-column-end: 7;
  }
  
  .polaroid-2{
    grid-column-start: 1;
    grid-column-end: 7;
  }
  
  .polaroid-3{
    grid-column-start: 1;
    grid-column-end: 7;
  }

}

@media screen and (max-width: 542px) {
  .heading{
      font-size: 60px;
    height: 8%;
  }

  .top-menu{
    top: 65px;
  }

  .heading1{
    font-size: 60px;
}

}

@media screen and (max-width: 350px) {
  .heading{
      font-size: 40px;
  }
  .heading1{
    font-size: 40px;
}
}