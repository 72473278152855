.App {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;  max-height: -webkit-fill-available;
  width: 100%;
  background-color: #f9f9f9;
}

.paragraph{
  font-size: 30px;
  position: relative;
  top: 50px;
  left: 5%;
  font-weight: 300;
  color:black;
}

 .image-container{
  max-width: 100%;
  position: relative;
  margin: 300px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
 }

 .image-container-portfolio{
  max-width: 100%;
  position: relative;
  margin: 300px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
 }

 .image-container-portfolio-1{
  max-width: 100%;
  position: relative;
  margin: 300px auto;
  margin-top: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
 }

 .image-container1{
  max-width: 100%;
  position: relative;
  margin: 300px auto;
  margin-top: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
 }

 .image-container-last{
  max-width: 100%;
  position: relative;
  margin: 300px auto;
  margin-bottom: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
 }

 .image-container-portfolio-last{
  max-width: 100%;
  position: relative;
  margin: 300px auto;
  margin-bottom: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
 }

 .img-settings {
  display: block;
  max-width: 95%;
  max-height:921px;
  
}

img { 
  max-width: 100%; 
}

 .image-container p{
   font-weight: 400;
   padding: 20px;
   font-size: 20px;
 }

 .image-container-portfolio p{
  font-weight: 400;
  padding: 20px;
  font-size: 20px;
  color: black;
}

.image-container-portfolio-1 p{
  font-weight: 400;
  padding: 20px;
  font-size: 20px;
  color: black;
}

.image-container-portfolio-last p{
  font-weight: 400;
  padding: 20px;
  font-size: 20px;
  color: black;
}


 .image-container1 p{
  font-weight: 400;
  padding: 20px;
  font-size: 20px;
  color: black;
}

.image-container-last p{
  
  font-weight: 400;
  padding: 20px;
  font-size: 20px;
  color: black;
}

 .image-container li{
  font-weight: 400;
  padding: 20px;
  font-size: 25px;
  list-style-type: none;
  
}

.menu-top{
  display:flex;
  position: relative;
  top: 100px;
  left: 5%;
  width: 280px;
}

.top-menu{
  display:flex;
  position: relative;
  left: 5%;
  width: 280px;
  margin-top: 5%;
  color: #f9f9f9;
}

.menu-bottom{
  display: flex;
  position: relative;
  bottom: 100px;
  left: 5%;
  width: 280px;
  
}

.bottom-menu{
  display: flex;
  position: relative;
  left: 5%;
  top: 10px;
  width: 280px;
  margin-bottom: 50px;
  color: #f9f9f9;
}

.list-menu{
  display: flex;
  padding-right: 20px;
  font-weight: 300;
}

.page-links{
  text-decoration: none;
  display: flex;
  font-weight: 300;
  color: #f9f9f9;
}

@media screen and (max-width: 542px) {
  .paragraph{
    font-size: 18px;
    top: 90px;
  }

  .menu-top{
    top: 120px;
  }
  
  .menu-bottom{
    bottom: 50px;
  }

  .image-container1{
    margin-top: 100px;
   }

   .image-container-last{
    margin-top: 100px;
   }

   .image-container{

    margin: 100px auto;
   }

   .image-container1{

    margin: 100px auto;
   }

   .image-container-last{

    margin: 100px auto;
   }

   .image-container p{
    font-size: 15px;
  }
 
  .image-container1 p{
   font-size: 15px;
 }
 
 .image-container-last p{
   font-size: 15px;
 }
 
  .image-container li{
   font-size: 15px;
 }


}


@media screen and (max-width: 350px) {
  .paragraph{
    font-size: 18px;
    top: 10px;
  }

  .menu-top{
    top: 30px;
  }
}