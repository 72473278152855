@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap);
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', serif;
}

body{
  background-color:#090909;
}

.App {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;  max-height: -webkit-fill-available;
  width: 100%;
  background-color: #f9f9f9;
}

.paragraph{
  font-size: 30px;
  position: relative;
  top: 50px;
  left: 5%;
  font-weight: 300;
  color:black;
}

 .image-container{
  max-width: 100%;
  position: relative;
  margin: 300px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  overflow: hidden;
 }

 .image-container-portfolio{
  max-width: 100%;
  position: relative;
  margin: 300px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  overflow: hidden;
 }

 .image-container-portfolio-1{
  max-width: 100%;
  position: relative;
  margin: 300px auto;
  margin-top: 200px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  overflow: hidden;
 }

 .image-container1{
  max-width: 100%;
  position: relative;
  margin: 300px auto;
  margin-top: 200px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  overflow: hidden;
 }

 .image-container-last{
  max-width: 100%;
  position: relative;
  margin: 300px auto;
  margin-bottom: 200px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  overflow: hidden;
 }

 .image-container-portfolio-last{
  max-width: 100%;
  position: relative;
  margin: 300px auto;
  margin-bottom: 200px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  overflow: hidden;
 }

 .img-settings {
  display: block;
  max-width: 95%;
  max-height:921px;
  
}

img { 
  max-width: 100%; 
}

 .image-container p{
   font-weight: 400;
   padding: 20px;
   font-size: 20px;
 }

 .image-container-portfolio p{
  font-weight: 400;
  padding: 20px;
  font-size: 20px;
  color: black;
}

.image-container-portfolio-1 p{
  font-weight: 400;
  padding: 20px;
  font-size: 20px;
  color: black;
}

.image-container-portfolio-last p{
  font-weight: 400;
  padding: 20px;
  font-size: 20px;
  color: black;
}


 .image-container1 p{
  font-weight: 400;
  padding: 20px;
  font-size: 20px;
  color: black;
}

.image-container-last p{
  
  font-weight: 400;
  padding: 20px;
  font-size: 20px;
  color: black;
}

 .image-container li{
  font-weight: 400;
  padding: 20px;
  font-size: 25px;
  list-style-type: none;
  
}

.menu-top{
  display:-webkit-flex;
  display:flex;
  position: relative;
  top: 100px;
  left: 5%;
  width: 280px;
}

.top-menu{
  display:-webkit-flex;
  display:flex;
  position: relative;
  left: 5%;
  width: 280px;
  margin-top: 5%;
  color: #f9f9f9;
}

.menu-bottom{
  display: -webkit-flex;
  display: flex;
  position: relative;
  bottom: 100px;
  left: 5%;
  width: 280px;
  
}

.bottom-menu{
  display: -webkit-flex;
  display: flex;
  position: relative;
  left: 5%;
  top: 10px;
  width: 280px;
  margin-bottom: 50px;
  color: #f9f9f9;
}

.list-menu{
  display: -webkit-flex;
  display: flex;
  padding-right: 20px;
  font-weight: 300;
}

.page-links{
  text-decoration: none;
  display: -webkit-flex;
  display: flex;
  font-weight: 300;
  color: #f9f9f9;
}

@media screen and (max-width: 542px) {
  .paragraph{
    font-size: 18px;
    top: 90px;
  }

  .menu-top{
    top: 120px;
  }
  
  .menu-bottom{
    bottom: 50px;
  }

  .image-container1{
    margin-top: 100px;
   }

   .image-container-last{
    margin-top: 100px;
   }

   .image-container{

    margin: 100px auto;
   }

   .image-container1{

    margin: 100px auto;
   }

   .image-container-last{

    margin: 100px auto;
   }

   .image-container p{
    font-size: 15px;
  }
 
  .image-container1 p{
   font-size: 15px;
 }
 
 .image-container-last p{
   font-size: 15px;
 }
 
  .image-container li{
   font-size: 15px;
 }


}


@media screen and (max-width: 350px) {
  .paragraph{
    font-size: 18px;
    top: 10px;
  }

  .menu-top{
    top: 30px;
  }
}
.container{
background-color: #0b0b0b;
height: auto;
position: relative;

}

.heading{    
  position: relative;
  height: 15%;
  font-size: 90px;
  left: 5%;
  font-weight: 300;
  width: 80%;
  color: #f9f9f9;
}

.heading1{    
  position: relative;
  height: 15%;
  font-size: 90px;
  left: 5%;
  font-weight: 300;
  width: 80%;
  color: #f9f9f9;
}



 .compPara{
  position: relative;
  left: 5%;
  font-weight: 300;  
  width: 80%;
  top: 30px;
  height: 0px;
 
}
.heading:hover ~ .cursor{
  -webkit-transform: scale(5);
          transform: scale(5);
}

.polaroids{
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 80px;
  margin-bottom: 160px;

}


.polaroid-1{
  position: relative;
  justify-self: center;
  grid-column-start: 1;
  grid-column-end: 3;
  max-width: 80%;
    
}

.polaroid-2{

  position: relative;
  justify-self: center;
  grid-column-start: 3;
  grid-column-end: 5;
  max-width: 80%;
}

.polaroid-3{

  position: relative;
  justify-self: center;
  grid-column-start: 5;
  grid-column-end: 7;
  max-width: 80%;

}

@media screen and (max-width: 1421px) and (min-width: 1082px) {

  .polaroid-1{
    grid-column-start: 1;
    grid-column-end: 4;
  }
  
  .polaroid-2{
      grid-column-start: 4;
    grid-column-end: 7;
  }
  
  .polaroid-3{
    grid-column-start: 2;
    grid-column-end: 5;
    left: 17.5%;
  }

}

@media screen and (max-width: 500px) and (min-width: 350px) {
  .polaroids{
    margin-top: 20px;
  }
}

@media screen and (max-width: 350px) {
  .polaroids{
    margin-top: 0px;
  }
}

@media screen and (max-width: 1082px) {

  .polaroid-1{
    grid-column-start: 1;
    grid-column-end: 7;
  }
  
  .polaroid-2{
    grid-column-start: 1;
    grid-column-end: 7;
  }
  
  .polaroid-3{
    grid-column-start: 1;
    grid-column-end: 7;
  }

}

@media screen and (max-width: 542px) {
  .heading{
      font-size: 60px;
    height: 8%;
  }

  .top-menu{
    top: 65px;
  }

  .heading1{
    font-size: 60px;
}

}

@media screen and (max-width: 350px) {
  .heading{
      font-size: 40px;
  }
  .heading1{
    font-size: 40px;
}
}
.about-me-container{

  height: 100vh;  max-height: -webkit-fill-available;
  position: relative;
    }


.about-me-container h1{
  
  
  font-weight: 400;
  max-width: 100%;
  padding: 10px;
  text-align: center;
  color: #f9f9f9;
}

.about-me-container p{
  
  
  font-weight: 400;
  position: relative;
  text-align: center;
  color: #f9f9f9;
  
}

.contact-me{
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  top: 2%;
  -webkit-justify-content: center;
          justify-content: center;

}


.position-1{
  position: relative;
  justify-self: center;
  grid-column-start: 2;
  grid-column-end: 6;
}

.position-2{
  position: relative;
  justify-self: center;
  grid-column-start: 1;
  grid-column-end: 7;
  margin-bottom: 80px;
  max-width: 80%;
}

.form-inline {
  text-align: center;

}

input{
  width: 100%;
  max-width: 90%;
  margin: 20px;
  background-color: #0b0b0b;
  border: 1px solid white;
  font-weight: 400;
  font-size: 16px;
  height: 27px;
  border-radius: 8px;
  text-align: center;
  color: #f9f9f9;
}

textarea{
  width: 100%;
  max-width: 90%;
  margin: 20px;
  resize: none;
  height: 300px;
  background-color: #0b0b0b;
  border: 1px solid white;
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px;
  text-align: center;
  color: #f9f9f9;
}

::-webkit-input-placeholder {
  color: white;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  color: white;
  opacity: 1; /* Firefox */
}

::placeholder {
  color: white;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: white;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: white;
}

.button{
  background-color: #0b0b0b;
  border: 1px solid white;
  width: 75px;
  height: 30px;
  color: #f9f9f9;
  font-size: 15px;
  font-weight: 400;
}

@media screen and (max-width: 600px) {

  .position-1{
    grid-column-start: 1;
    grid-column-end: 7;
    max-width: 90%;
  }

  .position-2{
    max-width: 100%;
  }
  
}
.heatpack-container{

  height: auto;
  position: relative;
    }
    
.heatpack-title-container{

      display:-webkit-flex;

      display:flex;
      position: relative;
      height: 15%;
      -webkit-align-items: center;
              align-items: center;
      font-size: 90px;
      color: #f9f9f9;
      left: 5%;
      overflow: hidden;
      
    }

.heatpack-title-container span{

  
  font-weight: 300;
}


.computer{
    
  position: absolute;
  display: -webkit-flex;
  display: flex;
  top: 270px;
  font-size: 90px; 
  overflow: hidden;
  left: 10%;
  color: #f9f9f9;
          
  }
    
.marquee{

    position: absolute;
    top: 160px;
    width:100%;
    overflow: hidden;
    z-index: 1; 
   }
   
  
.marquee-content{
    list-style: none;
    display: -webkit-flex;
    display: flex;
    
          
      }

.marquee-content span{
  
  font-weight: 300;
}
      
   @-webkit-keyframes scrolling{
    0% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
    100% {-webkit-transform: translate3d(-2400px,0,0);transform: translate3d(-2400px,0,0);}
    }
      
   @keyframes scrolling{
    0% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
    100% {-webkit-transform: translate3d(-2400px,0,0);transform: translate3d(-2400px,0,0);}
    }
      
.marquee-content li{
    -webkit-animation: scrolling 10s linear infinite;
            animation: scrolling 10s linear infinite;  
    display:-webkit-flex;  
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 800px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    font-size: 90px;
    white-space:nowrap;
    text-align: center;    
    font-weight: 300;
    color: #f9f9f9;

  }


.computer span{
  
  font-weight: 300;
}

.navHidden{
  height: 8.67;
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
-webkit-justify-content: center;
        justify-content: center;
width: 100%;
}


.first-Image {

  position:relative;
  display: -webkit-flex;
  display: flex;
  top: 1000px;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
}

.polaroid-container-1{
     
      position: relative;
      justify-self: center;
      grid-column: 1/3;
      max-width: 80%;
      
  }

.polaroid-container-2{
      position: relative;
      justify-self: center;
      grid-column: 3/5;
      max-width: 80%;
    }

.polaroid-container-3{
      position: relative;
      justify-self: center;
      grid-column: 5/7;
      max-width: 80%;

  }

  @media screen and (max-width: 500px) {
    .heatpack-title-container{
      font-size: 60px;
    }

    .computer{
      font-size: 60px;
      top: 195px;
      }
      .marquee{
        top: 120px;
      }

      .marquee-content li{
        font-size: 60px;
        width: 600px;
      }

      @-webkit-keyframes scrolling{
        0% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
        100% {-webkit-transform: translate3d(-1800px,0,0);transform: translate3d(-1800px,0,0);}
        }

      @keyframes scrolling{
        0% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
        100% {-webkit-transform: translate3d(-1800px,0,0);transform: translate3d(-1800px,0,0);}
        }
      
  }
  
  @media screen and (max-width: 350px) {
    .heatpack-title-container{
      font-size: 40px;
    }

    .computer{
      font-size: 40px;
      top: 150px; 
      }

      .marquee{
        top: 100px;
      }

      .marquee-content li{
        font-size: 40px;
        width: 400px;
      }

      @-webkit-keyframes scrolling{
        0% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
        100% {-webkit-transform: translate3d(-1200px,0,0);transform: translate3d(-1200px,0,0);}
        }

      @keyframes scrolling{
        0% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
        100% {-webkit-transform: translate3d(-1200px,0,0);transform: translate3d(-1200px,0,0);}
        }
  
  }
.exhibit-container{

  height: 100vh;  max-height: -webkit-fill-available;
  position: relative;
  background-size: cover;
}

.game-container{

  display: -webkit-flex;

  display: flex;
  position: relative;
  height: 720px;
  width: 100%;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  top: 30px;
}

.three-dots{
  position: absolute; 
  left: 50%;
  top: 50%;
  z-index: -1;
}

.three-dots-wrapper{
  display: block;
}

.loading{
  position: absolute;
  left: 48.2%;
  top: 54%;
  color: #f9f9f9;
  z-index: -1;
  

}

.mobilePC{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 70vh;
  color: #f9f9f9;
}

.mobilePCButton{
  margin: 2rem auto;
  text-decoration: none;
  font-size: 1.5rem;
  color: whitesmoke;
  padding: 14px 20px;
  margin-top: 70px;
  
  border: 3px solid whitesmoke;
  width:200px;
  text-align: center;

}

.question{
  
  text-align: center;
  font-size: 40px;
  font-weight: 100;
  
  margin-top: 80px;
  max-width: 85%;
}

.web-gl-pc{
  width: 80vw;
  position: relative;
}
.web-gl-pc-wrapper{
  position: relative;
  margin-top: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
}

@media screen and (max-width: 600px) {
 
  .question{
    font-size: 25px;
  }


}
.hero-container{
  background-image: url(/static/media/home-background.cf0aa8df.png);
  background-position: center;
  display: -webkit-flex;
  display: flex;
  height: 100vh;  max-height: -webkit-fill-available;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  overflow: hidden;
  background-size: cover;
  
}

.hide {
  display: none;
}

.show {
  list-style-type: none;
  display: inline-block;
  -webkit-align-self: center;
          align-self: center;
  position: relative;
  top: -8%;
}

.icons{
  width: 600px;
  height: 420px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.photo-icon {
  cursor: pointer;
  right: 7.5px;
  position: relative;
}

.img-container{
  width: 600px;
  height: 420px;
  position: relative;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
 -webkit-align-items: center;
         align-items: center;
}

.img-container1{
  width: 600px;
  height: 420px;
  position: relative;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.tech-icon {

  cursor: pointer;
  left: 7.5px;
  position: relative;
}

.display-none {

  display: none;
}


.figcap1{
  color: #fff;
  text-align: center;
  font-size: 25px;
  position: relative;
  bottom: 85px;
  left: 4px;

}

.figcap2{
  text-align: center;
  font-size: 25px;
  position: relative;
  bottom: 85px;
  right: 10px;
}

.photo-item {
  height: 28px;
}

.item-links {
  display: block;
  text-align: center;
  margin: 2rem auto;
  text-decoration: none;
  font-size: 1.5rem;
  background-color: transparent;
  color: #f9f9f9;
}

.welcomeMessage{
  position: absolute;
  top: -30px;
  left: 15px;
  font-weight: 200;
  width: 50vw;
  font-size: 89px;
  margin: 10px;
  opacity: 0;
  color: #f9f9f9;
}
.continueMessage{
  font-size: 70px;
  color: #f9f9f9;
}

@media screen and (max-width: 1657px) {
  .welcomeMessage{
    font-size: 80px;
  }

  .continueMessage{
    font-size: 60px;
  }
}

@media screen and (max-width: 1490px) {
  .welcomeMessage{
    font-size: 70px;
  }
  .continueMessage{
    font-size: 50px;
  }
}

@media screen and (max-width: 1312px) {
  .welcomeMessage{
    font-size: 60px;
  }
  .continueMessage{
    font-size: 40px;
  }
}

@media screen and (max-width: 1118px) {
  .welcomeMessage{
    font-size: 50px;
  }
  .continueMessage{
    font-size: 30px;
  }
}

@media screen and (max-width: 1199px) {
  .hero-container{
    
    background-image: url(/static/media/website-background.6ce6573f.png);
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .welcomeMessage{
    font-size: 50px;
    width: 90vw;
  }
  .continueMessage{
    font-size: 30px;
    width: 90vw;
  }

}

@media screen and (max-width: 603px) {
  .img-container{
    width: 100vw;
    height: 71vw;
  }

  .img-container1{
    width: 100vw;
    height: 71vw;
  }

  .icons{
    width: 100vw;
    height: 71vw;
  }
  .figcap1{
    bottom: 10vw;
  
  }
  
  .figcap2{
    bottom: 10vw;
  }

  }

  
  @media screen and (max-width: 526px) {
    .welcomeMessage{
      font-size: 35px;
    }
    .continueMessage{
      font-size: 25px;
    }
  }


@media screen and (max-width: 391px) {
  .welcomeMessage{
    font-size: 30px;
  }

  .continueMessage{
    font-size: 20px;
  }
}


@media screen and (max-height: 473px) {
  .img-container{
    width: 410px;
    height: 286px;
  }

  .img-container1{
    width: 410px;
    height: 286px;
  }

  .icons{
    width: 410px;
    height: 286px;
  }

}

@media screen and (min-height: 700px) and (min-width: 500px) and (max-width: 600px) {
  .img-container{
    width: 410px;
    height: 286px;
  }

  .img-container1{
    width: 410px;
    height: 286px;
  }

  .icons{
    width: 410px;
    height: 286px;
  }

  .figcap1{
    bottom: 45px;
  
  }
  
  .figcap2{
    bottom: 45px;
  }
}

@media screen and (max-height: 574px) {
  .img-container{
    width: 350px;
    height: 229px;
  }

  .img-container1{
    width: 350px;
    height: 229px;
  }

  .icons{
    width: 350px;
    height: 229px;
  }

  .figcap1{
    bottom: 45px;
  
  }
  
  .figcap2{
    bottom: 45px;
  }
}

@media screen and (max-height: 460px) {
  .img-container{
    width: 268px;
    height: 175px;
  }

  .img-container1{
    width: 268px;
    height: 175px;
  }

  .icons{
    width: 268px;
    height: 175px;
  }

  .figcap1{
    bottom: 45px;
  
  }
  
  .figcap2{
    bottom: 45px;
  }
}
.about-container{
height: 100vh;  max-height: -webkit-fill-available;
position: relative;
}

.about-me{
  max-width: 100%;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  overflow: hidden;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 20px;
}

.expand-button{
  background-color: #0b0b0b;
  border: 1px solid white;
  width: 85px;
  height: 25px;
  grid-column: 13/17;
  color: #f9f9f9;
}


.about-me p{
  padding: 10px;
  margin-bottom: 30px;
  color: #f9f9f9;
}

.about-me-passage p{
  
  padding: 10px;
  margin-bottom: 30px;
  color: #f9f9f9;
}

.about-me-name{
  display: -webkit-flex;
  display: flex;
  max-width: 500px;
  padding: 10px;
  font-size: 35px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  overflow: hidden;
}

.img-settings1 {
  display: block;
  max-width: 95%;
  max-height:500px;
}

.about-me-passage{
  width: 100vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2vw repeat(18,1fr) 2vw;
  grid-column-gap: 1.6rem;
  -webkit-column-gap: 1.6rem;
          column-gap: 1.6rem;
  position: relative;
  margin-bottom: 125px;
}

.section1{
  grid-column: 9/19;
  font-size: 35px;
  font-weight: 300;
  text-align: center;
}

.section7{
  grid-column: 9/19;
  font-size: 40px;
  font-weight: 300;
  text-align: center;
}

.section2{
  grid-column: 9/14;
  font-weight: 300;
}

.opacityblur {
  opacity: 0.6;
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

.section3{
  grid-column: 14/19;
  font-weight: 300;
}

.section4{
  grid-column: 1/19;
  font-size: 100px;
  text-align: center;
}

.section5{
  grid-column: 3/7;
}

.section6{

  grid-column: 9/19;
  font-weight: 300;
}

.section8{
  display: none;
}

.section9{
  grid-column: 3/7;
  text-align: center;
}

.vid-section{
  grid-column: 2/8;
  width: 35vw;
}

.pop-font{
  font-size: 20px;
  max-width: 75%;
  margin: auto;
  position: absolute;
  left: 0; right: 0;
  top: 20%;
  color: #f9f9f9;
}

.pop-up-heading{
  font-size: 50px;
  position: absolute;
  margin-bottom: 7%;
  left: 8%;
  top: 4%;
  color: #f9f9f9;
}

@media screen and (max-width: 767px){

  .pop-font{
    font-size: 15px;
  }

  .pop-up-heading{
    font-size: 30px;
  }
  
  .about-me-passage{
    display: block;
    width: 88vw;
  }

  .section4{
    font-size: 60px;
  }

  .section5{
    display: none;
  }

  .section2{
    text-align: center;
  }

  .section3{
    text-align: center;
  }

  .vid-section{
    width: 90vw;
  }

  .section1{
    text-align: center;
    font-size: 30px;
  }

  .section8{
    grid-column: 3/7;
    display: block;
    text-align: center;
  }

  .section7{
    font-size: 20px;
  }

  .expand-button{
    margin:0 auto;
    display:block;
  }

}
.error404{
    color: #f9f9f9;
    font-weight: 300;
    font-size: 90px;
    height: 75vh;
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;

    
}

.errorFlex{ 
    display: -webkit-flex; 
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
  
}

  @media screen and (max-width: 542px) {
    .error404{
        font-size: 50px;
    }
  }
  
  @media screen and (max-width: 350px) {
    .error404{
        font-size: 40px;
    }
  }
