.heatpack-container{

  height: auto;
  position: relative;
    }
    
.heatpack-title-container{

      display:flex;
      position: relative;
      height: 15%;
      align-items: center;
      font-size: 90px;
      color: #f9f9f9;
      left: 5%;
      overflow: hidden;
      
    }

.heatpack-title-container span{

  
  font-weight: 300;
}


.computer{
    
  position: absolute;
  display: flex;
  top: 270px;
  font-size: 90px; 
  overflow: hidden;
  left: 10%;
  color: #f9f9f9;
          
  }
    
.marquee{

    position: absolute;
    top: 160px;
    width:100%;
    overflow: hidden;
    z-index: 1; 
   }
   
  
.marquee-content{
    list-style: none;
    display: flex;
    
          
      }

.marquee-content span{
  
  font-weight: 300;
}
      
   @keyframes scrolling{
    0% {transform: translate3d(0,0,0);}
    100% {transform: translate3d(-2400px,0,0);}
    }
      
.marquee-content li{
    animation: scrolling 10s linear infinite;  
    display:flex;
    justify-content: center;
    width: 800px;
    flex-shrink: 0;
    font-size: 90px;
    white-space:nowrap;
    text-align: center;    
    font-weight: 300;
    color: #f9f9f9;

  }


.computer span{
  
  font-weight: 300;
}

.navHidden{
  height: 8.67;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
justify-content: center;
width: 100%;
}


.first-Image {

  position:relative;
  display: flex;
  top: 1000px;
  justify-content: space-around;
  align-items: center;
}

.polaroid-container-1{
     
      position: relative;
      justify-self: center;
      grid-column: 1/3;
      max-width: 80%;
      
  }

.polaroid-container-2{
      position: relative;
      justify-self: center;
      grid-column: 3/5;
      max-width: 80%;
    }

.polaroid-container-3{
      position: relative;
      justify-self: center;
      grid-column: 5/7;
      max-width: 80%;

  }

  @media screen and (max-width: 500px) {
    .heatpack-title-container{
      font-size: 60px;
    }

    .computer{
      font-size: 60px;
      top: 195px;
      }
      .marquee{
        top: 120px;
      }

      .marquee-content li{
        font-size: 60px;
        width: 600px;
      }

      @keyframes scrolling{
        0% {transform: translate3d(0,0,0);}
        100% {transform: translate3d(-1800px,0,0);}
        }
      
  }
  
  @media screen and (max-width: 350px) {
    .heatpack-title-container{
      font-size: 40px;
    }

    .computer{
      font-size: 40px;
      top: 150px; 
      }

      .marquee{
        top: 100px;
      }

      .marquee-content li{
        font-size: 40px;
        width: 400px;
      }

      @keyframes scrolling{
        0% {transform: translate3d(0,0,0);}
        100% {transform: translate3d(-1200px,0,0);}
        }
  
  }