.exhibit-container{

  height: 100vh;  max-height: -webkit-fill-available;
  position: relative;
  background-size: cover;
}

.game-container{

  display: flex;
  position: relative;
  height: 720px;
  width: 100%;
  justify-content: space-around;
  flex-shrink: 1;
  flex-direction: column-reverse;
  top: 30px;
}

.three-dots{
  position: absolute; 
  left: 50%;
  top: 50%;
  z-index: -1;
}

.three-dots-wrapper{
  display: block;
}

.loading{
  position: absolute;
  left: 48.2%;
  top: 54%;
  color: #f9f9f9;
  z-index: -1;
  

}

.mobilePC{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
  color: #f9f9f9;
}

.mobilePCButton{
  margin: 2rem auto;
  text-decoration: none;
  font-size: 1.5rem;
  color: whitesmoke;
  padding: 14px 20px;
  margin-top: 70px;
  
  border: 3px solid whitesmoke;
  width:200px;
  text-align: center;

}

.question{
  
  text-align: center;
  font-size: 40px;
  font-weight: 100;
  
  margin-top: 80px;
  max-width: 85%;
}

.web-gl-pc{
  width: 80vw;
  position: relative;
}
.web-gl-pc-wrapper{
  position: relative;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-content: center;
}

@media screen and (max-width: 600px) {
 
  .question{
    font-size: 25px;
  }


}