.about-me-container{

  height: 100vh;  max-height: -webkit-fill-available;
  position: relative;
    }


.about-me-container h1{
  
  
  font-weight: 400;
  max-width: 100%;
  padding: 10px;
  text-align: center;
  color: #f9f9f9;
}

.about-me-container p{
  
  
  font-weight: 400;
  position: relative;
  text-align: center;
  color: #f9f9f9;
  
}

.contact-me{
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  top: 2%;
  justify-content: center;

}


.position-1{
  position: relative;
  justify-self: center;
  grid-column-start: 2;
  grid-column-end: 6;
}

.position-2{
  position: relative;
  justify-self: center;
  grid-column-start: 1;
  grid-column-end: 7;
  margin-bottom: 80px;
  max-width: 80%;
}

.form-inline {
  text-align: center;

}

input{
  width: 100%;
  max-width: 90%;
  margin: 20px;
  background-color: #0b0b0b;
  border: 1px solid white;
  font-weight: 400;
  font-size: 16px;
  height: 27px;
  border-radius: 8px;
  text-align: center;
  color: #f9f9f9;
}

textarea{
  width: 100%;
  max-width: 90%;
  margin: 20px;
  resize: none;
  height: 300px;
  background-color: #0b0b0b;
  border: 1px solid white;
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px;
  text-align: center;
  color: #f9f9f9;
}

::placeholder {
  color: white;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: white;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: white;
}

.button{
  background-color: #0b0b0b;
  border: 1px solid white;
  width: 75px;
  height: 30px;
  color: #f9f9f9;
  font-size: 15px;
  font-weight: 400;
}

@media screen and (max-width: 600px) {

  .position-1{
    grid-column-start: 1;
    grid-column-end: 7;
    max-width: 90%;
  }

  .position-2{
    max-width: 100%;
  }
  
}