.hero-container{
  background-image: url('C:\Users\HEATPACK_OLLIE\Desktop\photo-and-tech\src\public\images/home-background.png');
  background-position: center;
  display: flex;
  height: 100vh;  max-height: -webkit-fill-available;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  overflow: hidden;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  
}

.hide {
  display: none;
}

.show {
  list-style-type: none;
  display: inline-block;
  align-self: center;
  position: relative;
  top: -8%;
}

.icons{
  width: 600px;
  height: 420px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.photo-icon {
  cursor: pointer;
  right: 7.5px;
  position: relative;
}

.img-container{
  width: 600px;
  height: 420px;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
 align-items: center;
}

.img-container1{
  width: 600px;
  height: 420px;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tech-icon {

  cursor: pointer;
  left: 7.5px;
  position: relative;
}

.display-none {

  display: none;
}


.figcap1{
  color: #fff;
  text-align: center;
  font-size: 25px;
  position: relative;
  bottom: 85px;
  left: 4px;

}

.figcap2{
  text-align: center;
  font-size: 25px;
  position: relative;
  bottom: 85px;
  right: 10px;
}

.photo-item {
  height: 28px;
}

.item-links {
  display: block;
  text-align: center;
  margin: 2rem auto;
  text-decoration: none;
  font-size: 1.5rem;
  background-color: transparent;
  color: #f9f9f9;
}

.welcomeMessage{
  position: absolute;
  top: -30px;
  left: 15px;
  font-weight: 200;
  width: 50vw;
  font-size: 89px;
  margin: 10px;
  opacity: 0;
  color: #f9f9f9;
}
.continueMessage{
  font-size: 70px;
  color: #f9f9f9;
}

@media screen and (max-width: 1657px) {
  .welcomeMessage{
    font-size: 80px;
  }

  .continueMessage{
    font-size: 60px;
  }
}

@media screen and (max-width: 1490px) {
  .welcomeMessage{
    font-size: 70px;
  }
  .continueMessage{
    font-size: 50px;
  }
}

@media screen and (max-width: 1312px) {
  .welcomeMessage{
    font-size: 60px;
  }
  .continueMessage{
    font-size: 40px;
  }
}

@media screen and (max-width: 1118px) {
  .welcomeMessage{
    font-size: 50px;
  }
  .continueMessage{
    font-size: 30px;
  }
}

@media screen and (max-width: 1199px) {
  .hero-container{
    
    background-image: url('C:\Users\HEATPACK_OLLIE\Desktop\photo-and-tech\src\public\images/website-background.png');
    flex-direction: column;
  }

  .welcomeMessage{
    font-size: 50px;
    width: 90vw;
  }
  .continueMessage{
    font-size: 30px;
    width: 90vw;
  }

}

@media screen and (max-width: 603px) {
  .img-container{
    width: 100vw;
    height: 71vw;
  }

  .img-container1{
    width: 100vw;
    height: 71vw;
  }

  .icons{
    width: 100vw;
    height: 71vw;
  }
  .figcap1{
    bottom: 10vw;
  
  }
  
  .figcap2{
    bottom: 10vw;
  }

  }

  
  @media screen and (max-width: 526px) {
    .welcomeMessage{
      font-size: 35px;
    }
    .continueMessage{
      font-size: 25px;
    }
  }


@media screen and (max-width: 391px) {
  .welcomeMessage{
    font-size: 30px;
  }

  .continueMessage{
    font-size: 20px;
  }
}


@media screen and (max-height: 473px) {
  .img-container{
    width: 410px;
    height: 286px;
  }

  .img-container1{
    width: 410px;
    height: 286px;
  }

  .icons{
    width: 410px;
    height: 286px;
  }

}

@media screen and (min-height: 700px) and (min-width: 500px) and (max-width: 600px) {
  .img-container{
    width: 410px;
    height: 286px;
  }

  .img-container1{
    width: 410px;
    height: 286px;
  }

  .icons{
    width: 410px;
    height: 286px;
  }

  .figcap1{
    bottom: 45px;
  
  }
  
  .figcap2{
    bottom: 45px;
  }
}

@media screen and (max-height: 574px) {
  .img-container{
    width: 350px;
    height: 229px;
  }

  .img-container1{
    width: 350px;
    height: 229px;
  }

  .icons{
    width: 350px;
    height: 229px;
  }

  .figcap1{
    bottom: 45px;
  
  }
  
  .figcap2{
    bottom: 45px;
  }
}

@media screen and (max-height: 460px) {
  .img-container{
    width: 268px;
    height: 175px;
  }

  .img-container1{
    width: 268px;
    height: 175px;
  }

  .icons{
    width: 268px;
    height: 175px;
  }

  .figcap1{
    bottom: 45px;
  
  }
  
  .figcap2{
    bottom: 45px;
  }
}